export default {

  domainCRM: 'https://app-preprod.reorganiza.pt/public/api',
  domainLeadCenter: 'https://leadcenter.pt/lead_center/api/public',

  //General call API:

  /*GET*/
  call_api_get: async function(url) {
    try {
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      var result = [];

      if (response.status == 200) result = await response.json();
      else if (response.status == 500) await this.call_api_get(url);

      return result;
    } catch (error) {
      console.error('Error:', error, 'in url:', url);
      return [];
    }
  },

  /*GET SPECIAL OUTPUT*/
  call_api_get_special: async function(url) {
    try {
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
        },
        mode: 'no-cors',
      });

      var result = [];

      if (response.status == 200) result = await response.json();

      return result;
    } catch (error) {
      console.error('Error:', error, 'in url:', url);
      return [];
    }
  },

  //POST
  call_api_post: async function(url, apiBody) {
    //console.log('em api body do api post', url, 'apiBody', apiBody);

    try {
      var response = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(apiBody, null, 2),
      });

      var result = [];

      if (response.status == 200) result = await response.json();

      return result;
    } catch (error) {
      console.error('Error:', error, 'in url:', url);
      return 'Error: ' + error;
    }
  },

  //****************Calls API:

  simulador_seguros: async function(payload) {
    let url = `https://comparaseguros.pt/public/api/simulations/retrieve-simulation/${payload.dataNascimento}/${payload.dataNascimentoSegundo}/${payload.capitalDivida}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_leads: async function(user_level, username) {
    let url;

    if (user_level > 1) {
      url = `${this.domainCRM}/all-leads-in-process-new`;
    } else {
      url = `${this.domainCRM}/my-leads-in-process-new/${username}`;
    }

    let result = await this.call_api_get(url);

    return result;
  },
  collect_consultores: async function() {
    let url = `${this.domainCRM}/consultores`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_lead_details: async function(id) {
    let url = `${this.domainCRM}/lead-p-details/${id}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_lead_process: async function(process_id) {
    let url = `${this.domainCRM}/lead-process-logs/results/${process_id}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_extra_lead_data: async function(lead_id) {
    let url = `${this.domainCRM}/consultor/lead/extra_detail/${lead_id}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_extra_lead_data_all: async function(lead_id) {
    let url = `${this.domainCRM}/consultor/lead/extra_detail_all/`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_leads_disabled: async function() {
    let url = `${this.domainCRM}/all-leads-in-process-new-disabled`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_leads_enabled: async function() {
    let url = `${this.domainCRM}/all-leads-in-process-new-enabled`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_leads_enabled_excel: async function() {
    let url = `${this.domainCRM}/all-leads-in-process-new-enabled-excel`;

    let result = await this.call_api_get(url);

    return result;
  },
  my_leads_in_process_new: async function(username) {
    let url = `${this.domainCRM}/my-leads-in-process-new/${username}`;

    let result = await this.call_api_get(url);

    return result;
  },
  get_process_step_result: async function(process_id) {
    let url = `${this.domainCRM}/lead-process-step-result/${process_id}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_possible_results: async function(current_step_id) {
    let url = `${this.domainCRM}/possible-results/${current_step_id}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_lead_history: async function(current_step_id) {
    let url = `${this.domainCRM}/lead-process-logs/results/${current_step_id}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_lead_documents: async function(lead_process_id) {
    let url = `${this.domainCRM}/lead_process_files/${lead_process_id}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_my_callbacks: async function(username) {
    let url = `${this.domainCRM}/my-callbacks/${username}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_my_leads_by_step: async function(username) {
    let url = `${this.domainCRM}/my-leads-by-step/${username}`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_new_leads_total: async function() {
    let url = `${this.domainCRM}/new-leads-total`;

    let result = await this.call_api_get(url);

    return result;
  },
  collect_my_goal: async function(username) {
    let url = `${this.domainCRM}/get-kpi/${username}`;

    let result = await this.call_api_get(url);

    return result;
  },
  leads_disabled_consultor: async function(username) {
    let url = `${this.domainCRM}/leads-disabled/${username}`;

    let result = await this.call_api_get(url);

    return result;
  },
  set_kpi: async function(payload) {
    let url = `${this.domainCRM}/set-kpi`;

    let result = await this.call_api_post(url, payload);

    return result;
  },
  get_all_kpi: async function(payload) {
    let url = `${this.domainCRM}/get-all-kpi`;

    let result = await this.call_api_post(url, payload);

    return result;
  },
  collect_history: async function(payload) {
    let url = `${this.domainCRM}/historico-cliente`;

    let result = await this.call_api_post(url, payload);

    return result;
  },
  collect_report_data: async function(payload) {
    let url = `${this.domainCRM}/report-data`;

    let result = await this.call_api_post(url, payload);

    return result;
  },
  
  set_new_lead_unchanged: async function(payload) {
    let url = `${this.domainCRM}/lead-process/new-result-unchanged/`;

    let result = await this.call_api_post(url, payload);
    // console.log('cheguei', result)
    return result;
  },
  set_new_lead: async function(payload) {
    let url = `${this.domainCRM}/lead-process/new-result/`;

    let result = await this.call_api_post(url, payload);
    // console.log('cheguei', result)
    return result;
  },
  update_lead_original: async function(payload) {
    let body  = payload
    let url = `${this.domainCRM}/update-lead-original/${body.id}`;
    
    let result = await this.call_api_post(url, payload);
    // console.log('cheguei', result)
    return result;
  },
  update_lead_details: async function(payload) {
    let { body, process_id } = payload;
    let url = `${this.domainCRM}/update-lead-details/${process_id}`;

    let result = await this.call_api_post(url, body);

    return result;
  },
  set_activate_lead: async function(body) {
    let url = `${this.domainCRM}/lead-process/activate/`;

    let result = await this.call_api_post(url, body);

    return result;
  },
  collect_leads_filters: async function(body) {
    let url = `${this.domainCRM}/lead-report`;

    let result = await this.call_api_post(url, body);

    return result;
  },
  login: async function(body) {
    let url = `${this.domainCRM}/login/`;

    let result = await this.call_api_post(url, body);

    return result;
  },
  products: async function() {
    let url = `${this.domainCRM}/products`;

    let result = await this.call_api_get(url);

    return result;
  },
  process_results: async function() {
    let url = `${this.domainCRM}/process-results`;

    let result = await this.call_api_get(url);

    return result;
  },
  update_pass: async function(payload) {
    let url = `${this.domainCRM}/update-pass/${payload.username}`;

    let result = await this.call_api_post(url, payload.apiBody);

    return result;
  },
  refresh_leads: async function() {
    let url = 'https://leadcenter.pt/lead_center/leads/cron_lead_deliver.php';

    let result = await this.call_api_get_special(url);

    return result;
  },
};
